/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';


// start the Stimulus application
import './bootstrap';

import $ from 'jquery';

import 'lightbox2/dist/css/lightbox.min.css';
import 'lightbox2';

import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const button_sitemap = $('#openSitemap');

// video + logo + navigation parallax
gsap.to('video', {
    yPercent: 20,
    ease: "none",
    scrollTrigger: {
        trigger: '.videoFrame',
        toggleActions: "restart pause reverse pause",
        start: "top top",
        end: "bottom top",
        scrub: true,
    }
})

gsap.to('.logocircleFrame', {
    yPercent: 20,
    autoAlpha: 0,
    ease: "none",
    scrollTrigger: {
        trigger: '.videoFrame',
        toggleActions: "restart pause reverse pause",
        start: "top top",
        end: "center 50",
        scrub: true,
    }
})

gsap.to('.bow_01', {
    scale: 1.2,
    yPercent: -5,
    xPercent: -10,
    autoAlpha: 0.5,
    ease: "none",
    scrollTrigger: {
        trigger: '.videoFrame',
        toggleActions: "restart pause reverse pause",
        start: "top top",
        end: "bottom top",
        scrub: true,
    }
})

gsap.to('.bow_02', {
    yPercent: 10,
    ease: "none",
    scrollTrigger: {
        trigger: '.videoFrame',
        toggleActions: "restart pause reverse pause",
        start: "top top",
        end: "bottom top",
        scrub: true,
    }
})

// sitmap open

gsap.to('.naviTopLeft, .naviTopRight', {
    // yPercent: 20,
    autoAlpha: 0,
    ease: "none",
    scrollTrigger: {
        trigger: '.videoFrame',
        toggleActions: "restart pause reverse pause",
        start: "top top",
        end: "center 50",
        scrub: true,
    }
})

// button color sitemap

const tl_sitemap = gsap.timeline({paused: true});
tl_sitemap
    .set('.sitemap', {display: 'inline'})
    .to('.sitemap', {x: -5000, duration: 0.3, ease: "Power4.out"}, 0)

button_sitemap.click(function () {
    tl_sitemap.play();
});

button_sitemap.mouseover(function () {
    $(".naviTopIconMenu").css({fill: "#262626", transition: "0.3s"});
});

button_sitemap.mouseleave(function () {
    $(".naviTopIconMenu").css({fill: "#fff", transition: "0.3s"});
});

// button color facebook

$('.naviTopFacebook').mouseover(function () {
    $(".naviTopIconFacebook").css({fill: "#262626", transition: "0.3s"});
});

$('.naviTopFacebook').mouseleave(function () {
    $(".naviTopIconFacebook").css({fill: "#fff", transition: "0.3s"});
});

// button color instagram

$('.naviTopInstagram').mouseover(function () {
    $(".naviTopIconInstagram").css({fill: "#262626", transition: "0.3s"});
});

$('.naviTopInstagram').mouseleave(function () {
    $(".naviTopIconInstagram").css({fill: "#fff", transition: "0.3s"});
});

// button color language

$('.button.naviTopLanguage').mouseover(function () {
    $(this).css({backgroundColor: "#262626", transition: "0.2s"});
});

$('.button.naviTopLanguage').mouseleave(function () {
    $(this).css({backgroundColor: "#fff", transition: "0.2s"});
});

// button color login/out

$('.button.naviTopLogin').mouseover(function () {
    $(this).css({backgroundColor: "#a81a1a", transition: "0.2s"});
});

$('.button.naviTopLogin').mouseleave(function () {
    $(this).css({backgroundColor: "#262626", transition: "0.2s"});
});

$('.button.naviTopLogout').mouseover(function () {
    $(this).css({backgroundColor: "#262626", transition: "0.2s"});
});

$('.button.naviTopLogout').mouseleave(function () {
    $(this).css({backgroundColor: "#a81a1a", transition: "0.2s"});
});
